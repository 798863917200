import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import englishContent from './languages/english';
import spanishContent from './languages/spanish';
import './assets/styles/App.css';
import { MdEmail, MdGTranslate } from "react-icons/md";
import { 
  FaPhoneAlt,
  FaClock,
  FaPaintBrush,
  FaDesktop,
  FaSearch,
  FaCloudUploadAlt,
  FaTools,
  FaCode, 
  FaCogs, 
  FaUser, 
  FaArrowsAltV, 
  FaShieldAlt, 
  FaPlug, 
  FaCog, 
  FaSkype, 
  FaLinkedin, 
  FaTiktok, 
  FaFacebookSquare, 
} from 'react-icons/fa';
import { FaSquareInstagram, FaLocationDot } from "react-icons/fa6";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import SoftwareIMG from './assets/images/software-img.png'
import Automation from './assets/images/automation.jpg'
import logo from "./assets/images/logo.png";
import testimonial1 from "./assets/images/testimonial_img1.jpeg";
import testimonial2 from "./assets/images/testimonial_img2.jpg";
import testimonial3 from "./assets/images/testimonial_img3.jpeg";

function App() {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('english');
  const content = language === 'english' ? englishContent : spanishContent;

  useEffect(() => {
      const timer = setTimeout(() => setLoading(false), 1000);

      return () => clearTimeout(timer); 
  }, []);

  const handleChangeLanguage = (e) => {
      setLanguage(e.target.value);
  };

  if (loading) {
    return (
      <div className="preloader">
        <div className="sk-spinner sk-spinner-rotating-plane"></div>
      </div>
    );
  }

  return (
    <>
      <div>
        <Helmet>
          <title>Dyteg - Advanced Software Services and Custom Web Design</title>
          <meta name="description" content="Dyteg offers tailored website design, custom software development, Google optimization, and specialized technical support." />
          <meta name="keywords" content="Dyteg, tailored website design, responsive web design, Google optimization, domain, custom software development, real-time applications, process automation, user interface enhancements, new module creation, software maintenance, updates, technical support" />
          <link rel="canonical" href="https://www.dyteg.com/" />
          {/* Open Graph for Facebook */}
          <meta property="og:title" content="Dyteg - Innovation and Technology in Software Development" />
          <meta property="og:description" content="Explore our custom web design services and advanced software solutions at Dyteg." />
          <meta property="og:image" content="https://www.dyteg.com//logo192.png" />
          <meta property="og:url" content="https://www.dyteg.com/" />
          
          <meta name="google-site-verification" content="NqdLEHmE9rdilh9WfEqXpv-qqnqu0nIuGbW678tnzS0" />
          <link rel="icon" type="image/x-icon" href="./assets/images/favicon.ico" />
        </Helmet>
        <header>
          <div className="top_nav">
              <div className="container">
                  <ul className="list-inline info row col-12 text-start">
                      <li className="col-md-6 col-sm-12 mt-2"><a href="tel:+16152905576"><FaPhoneAlt className="icon" /> {content.contactForm.phoneText}</a></li>
                      <li className="col-md-6 col-sm-12 mt-2"><a href="mailto:OfficialDyteg@gmail.com"><MdEmail className="icon" /> OfficialDyteg@gmail.com</a></li>
                      <li className="col-md-6 col-sm-12 mt-2">
                          <a href="#"><FaClock className="icon" /> {content.schedule.week} 7:00 - 18:00</a><br/>
                          <a href="#"><FaClock className="icon" /> {content.schedule.weekend} 7:00 - 12:00</a>
                      </li>
                      <li className="col-md-6 col-sm-12 mt-2 row">
                          <div className="col-6"><MdGTranslate /> {content.Language}</div>
                          <div className="col-6">
                              <select className="form-select" value={language} onChange={handleChangeLanguage}>
                                  <option value="english">English</option>
                                  <option value="spanish">Español</option>
                              </select>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
          <nav className="navbar navbar-expand-lg bg-info">
              <div className="container-fluid">
                  <a className="navbar-brand text-color" href="#"><img src={logo} className="logo" alt="Logo company" /> Dyteg</a>
                  <div>
                      <HiOutlineDesktopComputer className='icon-menu mx-auto' type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"/>
                  </div>
                  <div className="collapse navbar-collapse" id="navbarNav">
                      <ul className="navbar-nav">
                          <li className="nav-item">
                              <a href=""> {content.nav.home} </a>
                          </li>
                          <li className="nav-item">
                              <a href="#pricing"> {content.nav.services} </a>
                          </li>
                          <li className="nav-item">
                              <a href="#contact"> {content.nav.contact} </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>
        </header>

        <section id="home">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10 wow fadeIn" data-wow-delay="0.3s">
                  <h1 className="text-upper">{content.home.heading1}</h1>
                  <p className="tm-white">{content.home.description}</p>
                  <img src={SoftwareIMG} className="img-responsive" alt="home img" />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
        </section>

        <section id="divider">
          <div className="container">
            <div className="row">
              <div className="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
                <FaPaintBrush className='services-icons' />
                <h3 className="text-uppercase">{content.services.items[0].title}</h3>
                <p>{content.services.items[0].description}</p>
              </div>
              <div className="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
                <FaDesktop className='services-icons' />
                <h3 className="text-uppercase">{content.services.items[1].title}</h3>
                <p>{content.services.items[1].description}</p>
              </div>
              <div className="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
                <FaCode className='services-icons' />
                <h3 className="text-uppercase">{content.services.items[2].title}</h3>
                <p>{content.services.items[2].description}</p>
              </div>
              <div className="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
                <FaSearch className='services-icons' />
                <h3 className="text-uppercase">{content.services.items[3].title}</h3>
                <p>{content.services.items[3].description}</p>
              </div>
              <div className="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
                <FaCloudUploadAlt className='services-icons' />
                <h3 className="text-uppercase">{content.services.items[4].title}</h3>
                <p>{content.services.items[4].description}</p>
              </div>
              <div className="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
                <FaTools className='services-icons' />
                <h3 className="text-uppercase">{content.services.items[5].title}</h3>
                <p>{content.services.items[5].description}</p>
              </div>
            </div>
          </div>
        </section>   

        <section id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wow bounceIn">
                <h2 className="text-uppercase">{content.heading}</h2>
              </div>
              <div className="col-md-4 wow fadeIn" data-wow-delay="0.6s">
                <div className="pricing text-uppercase">
                  <div className="pricing-title">
                    <h4>{content.services.packages[0].title}</h4>
                  </div>
                  <ul>
                    <li>{content.services.packages[0].features[0]}</li>
                    <li>{content.services.packages[0].features[1]}</li>
                    <li>{content.services.packages[0].features[2]}</li>
                    <li>{content.services.packages[0].features[3]}</li>
                    <li>{content.services.packages[0].features[4]}</li>
                  </ul>
                  <a href={content.contactForm.skypeLink} target="_blank" onclick="event.preventDefault();">
                    <button className="btn btn-primary text-uppercase">{content.services.packages[0].button}</button>
                  </a>
                </div>
              </div>
              <div className="col-md-4 wow fadeIn" data-wow-delay="0.6s">
              <div className="pricing text-uppercase">
                  <div className="pricing-title">
                    <h4>{content.services.packages[1].title}</h4>
                  </div>
                  <ul>
                    <li>{content.services.packages[1].features[0]}</li>
                    <li>{content.services.packages[1].features[1]}</li>
                    <li>{content.services.packages[1].features[2]}</li>
                    <li>{content.services.packages[1].features[3]}</li>
                    <li>{content.services.packages[1].features[4]}</li>
                    <li>{content.services.packages[1].features[5]}</li>
                    <li>{content.services.packages[1].features[6]}</li>
                    <li>{content.services.packages[1].features[7]}</li>
                    <li>{content.services.packages[1].features[8]}</li>
                    <li>{content.services.packages[1].features[9]}</li>
                  </ul>
                  <a href={content.contactForm.skypeLink} target="_blank" onclick="event.preventDefault();">
                    <button className="btn btn-primary text-uppercase">{content.services.packages[1].button}</button>
                  </a>                
              </div>
              </div>
              <div className="col-md-4 wow fadeIn" data-wow-delay="0.6s">
                <div className="pricing text-uppercase">
                  <div className="pricing-title">
                    <h4>{content.services.packages[2].title}</h4>
                  </div>
                  <ul>
                    <li>{content.services.packages[2].features[0]}</li>
                    <li>{content.services.packages[2].features[1]}</li>
                    <li>{content.services.packages[1].features[2]}</li>
                    <li>{content.services.packages[2].features[3]}</li>
                    <li>{content.services.packages[2].features[4]}</li>
                    <li>{content.services.packages[2].features[5]}</li>
                    <li>{content.services.packages[2].features[6]}</li>
                    <li>{content.services.packages[2].features[7]}</li>
                    <li>{content.services.packages[2].features[8]}</li>
                    <li>{content.services.packages[2].features[9]}</li>
                    <li>{content.services.packages[2].features[10]}</li>
                    <li>{content.services.packages[1].features[8]}</li>
                    <li>{content.services.packages[2].features[11]}</li>
                    <li>{content.services.packages[2].features[12]}</li>
                  </ul>
                  <a href={content.contactForm.skypeLink} target="_blank" onclick="event.preventDefault();">
                    <button className="btn btn-primary text-uppercase">{content.services.packages[2].button}</button>
                  </a>   
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section id="feature">
          <div className="container">
            <div className="row">
              <div className="col-md-7 wow fadeInLeft" data-wow-delay="0.6s">
                <h2 className="text-uppercase">{content.feature.heading}</h2>
                <p>{content.feature.description}</p>
                <ul>
                  <li className='row mb-4'>
                    <div className='col-2'>
                      <i className="fa fa-check"><FaCogs /></i>
                    </div>
                    <div className='col-10 mt-2'>
                      {content.feature.featuresList[0].title}
                    </div>
                  </li>
                  <li className='row mb-4'>
                    <div className='col-2'>
                      <i className="fa fa-check"><FaUser /></i>
                    </div>
                    <div className='col-10 mt-2'>
                      {content.feature.featuresList[1].title}
                    </div>
                  </li>
                  <li className='row mb-4'>
                    <div className='col-2'>
                      <i className="fa fa-check"><FaArrowsAltV /></i>
                    </div>
                    <div className='col-10 mt-2'>
                      {content.feature.featuresList[2].title}
                    </div>
                  </li>
                  <li className='row mb-4'>
                    <div className='col-2'>
                      <i className="fa fa-check"><FaShieldAlt /></i>
                    </div>
                    <div className='col-10 mt-2'>
                      {content.feature.featuresList[3].title}
                    </div>
                  </li>
                  <li className='row mb-4'>
                    <div className='col-2'>
                      <i className="fa fa-check"><FaPlug /></i>
                    </div>
                    <div className='col-10 mt-2'>
                      {content.feature.featuresList[4].title}
                    </div>
                  </li>
                  <li className='row mb-4'>
                    <div className='col-2'>
                      <i className="fa fa-check"><FaCog /></i>
                    </div>
                    <div className='col-10 mt-2'>
                      {content.feature.featuresList[5].title}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 wow fadeInRight" data-wow-delay="0.6s">
                <img src={SoftwareIMG} className="img-responsive" alt="feature img" />
              </div>
            </div>
          </div>
        </section>

        <section id="contact">
          <div id="carouselExampleCaptions" className="carousel slide">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={Automation} className="d-block w-100 blur-image" alt="..." />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div className='container'>
            <div className="row text-contac-us alert alert-info">
              <div className="col-md-6">
                  <h2 className="text-outline">{content.contactForm.heading}</h2>
                  <h2 className="second_heading">{content.contactForm.secondHeading}</h2>
                  <p className="text-outline">{content.contactForm.description}</p>
              </div>
              <div className="col-md-4 col-sm-12 mt-4 row">
                  <a className="col-12" href="tel:+16152905576"><FaPhoneAlt className="icon-contac-us" target="_blank" onclick="event.preventDefault();" /> {content.contactForm.phoneText}</a>
                  <a className="col-12" href={content.contactForm.skypeLink} target="_blank" onclick="event.preventDefault();" >
                    <FaSkype className="icon-contac-us" /> {content.contactForm.phoneText}
                  </a>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonial">
          <div className="container text-center testimonial_area">
              <h2>{content.testimonial.heading}</h2>
              <p>{content.testimonial.description}</p>

              <div className="row">
                  <div className="col-md-4 col-sm-12">
                      <div className="testimonial_item">
                          <div className="testimonial_content text-left">
                              <p>{content.testimonial.reviews[0].content}</p>
                          </div>
                          <div className="testimonials mx-auto">
                              <img src={testimonial1} alt="Testimonial" />
                          </div>
                          <p className="worker_name">John Doe</p>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                      <div className="testimonial_item">
                          <div className="testimonial_content">
                              <p>{content.testimonial.reviews[1].content}</p>
                          </div>
                          <div className="testimonials mx-auto">
                              <img src={testimonial2} alt="Testimonial" />
                          </div>
                          <p className="worker_name">Jane Smith</p>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                      <div className="testimonial_item">
                          <div className="testimonial_content">
                              <p>{content.testimonial.reviews[2].content}</p>
                          </div>
                          <div className="testimonials mx-auto">
                              <img src={testimonial3} alt="Testimonial" />
                          </div>
                          <p className="worker_name">Mike Johnson</p>
                      </div>
                  </div>
              </div>
          </div>
        </section>
        <footer className='mt-5'>
          <div className="container footer_top">
              <div className="row">
                  <div className="col-lg-4 col-sm-7">
                      <div className="footer_item">
                          <h4>{content.footer.aboutCompany.heading}</h4>
                          <img src={logo} className="logo" alt="Logo company" /> 
                          <h3 className="nav-item">{content.footer.aboutCompany.companyName}</h3>
                          <p>{content.footer.aboutCompany.description}</p>

                          <ul className="list-inline footer_social_icon row">
                              <li className="col"><a target="_blank" onclick="event.preventDefault();" href="https://www.instagram.com/officialdyteg/"><FaSquareInstagram className="icon-contact-us" /></a></li>
                              <li className="col"><a target="_blank" onclick="event.preventDefault();" href="https://www.facebook.com/profile.php?id=61559321445088"><FaFacebookSquare className="icon-contact-us" /></a></li>
                              <li className="col"><a target="_blank" onclick="event.preventDefault();" href={content.contactForm.skypeLink}><FaSkype className="icon-contact-us" /></a></li>
                              <li className="col"><a target="_blank" onclick="event.preventDefault();" href="https://www.tiktok.com/@officialdyteg?_t=8mDWsK6cy7q&_r=1"><FaTiktok className="icon-contact-us" /></a></li>
                              <li className="col"><a target="_blank" onclick="event.preventDefault();" href="https://www.linkedin.com/company/concepcionconcrete/?viewAsMember=true"><FaLinkedin className="icon-contact-us" /></a></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-lg-3 col-sm-5">
                      <div className="footer_item">
                          <h4>{content.footer.aboutCompany.heading}</h4>
                          <ul className="list-unstyled footer_contact">
                              <li><a href=""><FaLocationDot /> 824 Oakwood Terrace Drive <br/> Antioch, TN 370113</a></li>
                              <li><a href="mailto:OfficialDyteg@gmail.com"><MdEmail className="icon" /> OfficialDyteg@gmail.com</a></li>
                              <li><a href="tel:+16152905576"><FaPhoneAlt className="icon" /> {content.contactForm.phoneText}</a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
          <div className="footer_bottom text-center">
              <p className="wow fadeInRight">
                  {content.footer.footerBottom.createdBy} <a target="_blank" href="https://www.linkedin.com/in/juan-andres-baron-baron/"><FaCode/></a>. {content.footer.footerBottom.rightsReserved}
              </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
