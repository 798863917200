const content = {
    Language: "Language",
    schedule: {
        week: "Mon - Fri",
        weekend: "Sat"
    },
    nav: {
      home: " Home ",
      about: " About Us ",
      services: " Services ",
      contact: " Contact Us ",
    },
    home: {
        heading1: "Custom Software Solutions",
        description: "Welcome to Dyteg, your trusted partner for custom software development, web design, logo creation, Google optimization, and process automation services. We specialize in creating modern, responsive, and innovative solutions for startups, businesses, and agencies.",
    },
    services: {
        heading: "OUR SERVICES",
        items: [
            {
                title: "Logo Creation",
                description: "Elevate your brand with unique and impactful logos that represent your identity and values."
            },
            {
                title: "Responsive Web Design",
                description: "Create stunning and user-friendly websites that adapt seamlessly to different devices, providing an optimal viewing experience."
            },
            {
                title: "Custom Software Development",
                description: "We specialize in tailored software solutions that meet your unique business needs, ensuring efficiency and scalability."
            },
            {
                title: "Google Optimization",
                description: "Improve your online visibility and reach with effective Google optimization strategies, enhancing your digital presence."
            },
            {
                title: "Deploys",
                description: "Efficiently deploy your software solutions to ensure seamless integration and optimal performance."
            },
            {
                title: "Software Maintenance",
                description: "Ensure the smooth operation of your software through proactive maintenance and timely updates, keeping it secure and functional."
            }
        ],
        packages: [
            {
                title: "Tailored Website Design",
                features: [
                    "Tailored Website Design",
                    "Responsive Web Design",
                    "Logo Creation",
                    "Google Optimization",
                    "Domain"
                ],
                message: "https://wa.me/+16152905576?text=I%20need%20more%20information%20about%20Tailored%20Website%20Design",
                button: "Get Started"
            },
            {
                title: "Custom Software Suite",
                features: [
                    "Tailored Website Design",
                    "Responsive Web Design",
                    "Logo Creation",
                    "Google Optimization",
                    "Domain",
                    "Custom Software Development",
                    "Real-time Applications",
                    "Process Automation",
                    "Advanced Features & Integrations",
                    "Technical Support"
                ],
                message: "https://wa.me/+16152905576?text=I%20need%20more%20information%20about%20Custom%20Software%20Suite",
                button: "Get Started"
            },
            {
                title: "Advanced Software Services",
                features: [
                    "Tailored Website Design",
                    "Responsive Web Design",
                    "Logo Creation",
                    "Google Optimization",
                    "Domain",
                    "Custom Software Development",
                    "Real-time Applications",
                    "Process Automation",
                    "User Interface Enhancements",
                    "New Module Creation",
                    "Software Maintenance & Updates",
                    "Technical Support"
                ],
                message: "https://wa.me/+16152905576?text=I%20need%20more%20information%20about%20Avance%20Software%20Services",
                button: "Get Started"
            }
        ]
    },     
    feature: {
        heading: "Our Software Features",
        description: "Discover the powerful features of our custom software solutions:",
        featuresList: [
          {
            title: "Tailored functionality to meet your specific business needs."
          },
          {
            title: "User-friendly interfaces for seamless navigation."
          },
          {
            title: "Scalability to grow with your business."
          },
          {
            title: "Robust security measures to protect your data."
          },
          {
            title: "Integration capabilities for enhanced efficiency."
          },
          {
            title: "Customizable options to suit your preferences."
          }
        ],
    },      
    testimonial: {
        heading: "Customer Reviews",
        description: "Explore our customers' opinions on our design, software and maintenance services.",
        reviews: [
            {
                content: "The Digital Brand Starter package helped us establish a strong online presence quickly. Excellent service!",
            },
            {
                content: "We're extremely satisfied with the Custom Software Suite. It perfectly tailored to our needs and boosted our efficiency.",
            },
            {
                content: "The Advanced Software Services have revolutionized how we operate. Highly recommend for complex software solutions.",
            }
        ]
    },
    contactForm: {
        heading: "Have questions or need a quote?",
        secondHeading: "Contact us today!",
        description: "We offer free consultations, requirements analysis, and quotes.",
        whatsappLink: "https://wa.me/16152905576?text=Hello,%20I'm%20interested%20in%20getting%20more%20information.%20Could%20you%20help%20me?",
        whatsappText: "615-290-5576",
        skypeLink: "https://join.skype.com/invite/mfcnV78WvH8q",
        phoneLink: "tel:+16152905576",
        phoneText: "615-290-5576",
        email: "officialdyteg@gmail.com"
    },    
    footer: {
        aboutCompany: {
            heading: "About Our Company",
            logoAlt: "Dyteg Logo",
            companyName: "Dyteg",
            description: "Discover more about our dedicated team and commitment to quality software services.",
        },
        contactUs: {
            heading: "Contact Us",
            address: "824 Oakwood Terrace Drive, Antioch, TN 370113",
        },
        footerBottom: {
            createdBy: "Made by",
            rightsReserved: "All Rights Reserved"
        }
    },    
  };
  
  export default content;
  