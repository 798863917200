const content = {
    Language: "Idioma",
    schedule: {
        week: "Lun - Vie",
        weekend: "Sáb"
    },
    nav: {
      home: " Inicio ",
      about: " Sobre Nosotros ",
      services: " Servicios ",
      contact: " Contáctanos ",
    },
    home: {
        heading1: "Soluciones de software personalizadas",
        description: "Bienvenido a Dyteg, su socio de confianza para servicios de desarrollo de software personalizado, diseño web, creación de logotipos, optimización de Google y automatización de procesos. Nos especializamos en la creación de soluciones modernas, receptivas e innovadoras para nuevas empresas, empresas y agencias.",
    },
    services: {
        heading: "NUESTROS SERVICIOS",
        items: [
            {
                title: "Creación de Logotipos",
                description: "Eleve su marca con logotipos únicos e impactantes que representen su identidad y valores."
            },
            {
                title: "Diseño Web Responsivo",
                description: "Cree sitios web impresionantes y fáciles de usar que se adapten perfectamente a diferentes dispositivos, ofreciendo una experiencia de visualización óptima."
            },
            {
                title: "Desarrollo de Software Personalizado",
                description: "Nos especializamos en soluciones de software a medida que satisfacen sus necesidades comerciales únicas, garantizando eficiencia y escalabilidad."
            },
            {
                title: "Optimización en Google",
                description: "Mejore su visibilidad y alcance en línea con estrategias efectivas de optimización en Google, mejorando su presencia digital."
            },
            {
                title: "Implementaciones",
                description: "Implemente eficientemente sus soluciones de software para garantizar una integración perfecta y un rendimiento óptimo."
            },
            {
                title: "Mantenimiento de Software",
                description: "Asegure el funcionamiento sin problemas de su software mediante un mantenimiento proactivo y actualizaciones oportunas, manteniéndolo seguro y funcional."
            }
        ],
        packages: [
            {
                title: "Diseño de Sitios Web Personalizados",
                features: [
                    "Diseño de Sitios Web Personalizados",
                    "Diseño Web Responsivo",
                    "Creación de Logotipos",
                    "Optimización en Google",
                    "Dominio"
                ],
                message: "https://wa.me/+16152905576?text=Necesito%20más%20información%20sobre%20el%20diseño%20de%20sitios%20web%20personalizados",
                button: "Empezar"
            },
            {
                title: "Suite de Software Personalizado",
                features: [
                    "Diseño de Sitios Web Personalizados",
                    "Diseño Web Responsivo",
                    "Creación de Logotipos",
                    "Optimización en Google",
                    "Dominio",
                    "Desarrollo de Software Personalizado",
                    "Aplicaciones en Tiempo Real",
                    "Automatización de Procesos",
                    "Funcionalidades y Integraciones Avanzadas",
                    "Soporte Técnico"
                ],
                message: "https://wa.me/+16152905576?text=Necesito%20más%20información%20sobre%20Suite%20de%20Software%20personalizado",
                button: "Empezar"
            },
            {
                title: "Servicios Avanzados de Software",
                features: [
                    "Diseño de Sitios Web Personalizados",
                    "Diseño Web Responsivo",
                    "Creación de Logotipos",
                    "Optimización en Google",
                    "Dominio",
                    "Desarrollo de Software Personalizado",
                    "Aplicaciones en Tiempo Real",
                    "Automatización de Procesos",
                    "Mejoras en la Interfaz de Usuario",
                    "Creación de Nuevos Módulos",
                    "Mantenimiento y Actualizaciones de Software",
                    "Soporte Técnico"
                ],
                message: "https://wa.me/+16152905576?text=Necesito%20más%20información%20sobre%20los%20Servicios%20Avanzados%20De%20Software",
                button: "Empezar"
            }
        ]
    }, 
    feature: {
        heading: "Nuestras Características de Software",
        description: "Descubre las potentes características de nuestras soluciones de software personalizado:",
        featuresList: [
          {
            title: "Funcionalidad a medida para satisfacer tus necesidades comerciales específicas."
          },
          {
            title: "Interfaces amigables para una navegación fluida."
          },
          {
            title: "Escalabilidad para crecer con tu negocio."
          },
          {
            title: "Medidas de seguridad robustas para proteger tus datos."
          },
          {
            title: "Capacidades de integración para una eficiencia mejorada."
          },
          {
            title: "Opciones personalizables para adaptarse a tus preferencias."
          }
        ],
      },         
    testimonial: {
        heading: "Reseñas de Clientes",
        description: "Explora las opiniones de nuestros clientes sobre nuestros servicios de diseño, software y mantenimiento.",
        reviews: [
            {
                content: "El paquete Digital Brand Starter nos ayudó a establecer rápidamente una sólida presencia en línea. ¡Excelente servicio!",
            },
            {
                content: "Estamos extremadamente satisfechos con Custom Software Suite. Se adaptó perfectamente a nuestras necesidades y aumentó nuestra eficiencia.",
            },
            {
                content: "Los Servicios de Software Avanzados han revolucionado nuestra forma de operar. Altamente recomendado para soluciones de software complejas.",
            }
        ]
    },
    contactForm: {
        heading: "¿Tienes preguntas o necesitas un presupuesto?",
        secondHeading: "¡Contáctanos hoy mismo!",
        description: "Ofrecemos consultas, análisis de requerimientos y presupuestos gratuitos.",
        whatsappLink: "https://wa.me/+16152905576?text=Hola,%20estoy%20interesado%20en%20obtener%20más%20información.%20¿Podrías%20ayudarme?",
        whatsappText: "615-290-5576",
        skypeLink: "https://join.skype.com/invite/mfcnV78WvH8q",
        phoneLink: "tel:+16152905576",
        phoneText: "615-290-5576",
        email: "officialdyteg@gmail.com"
    },    
    footer: {
        aboutCompany: {
            heading: "Acerca de Nuestra Empresa",
            logoAlt: "Logo de Dyteg",
            companyName: "Dyteg",
            description: "Descubre más sobre nuestro equipo dedicado y nuestro compromiso con servicios de software de calidad.",
        },
        contactUs: {
            heading: "Contáctenos",
            address: "824 Oakwood Terrace Drive, Antioch, TN 370113",
        },
        footerBottom: {
            createdBy: "Hecho por",
            rightsReserved: "Todos los derechos reservados"
        }
    }
  };
  
  export default content;
  